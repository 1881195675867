// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$web-frontend-primary: mat.define-palette(mat.$indigo-palette);
$web-frontend-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$web-frontend-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$web-frontend-theme: mat.define-light-theme(
  (
    color: (
      primary: $web-frontend-primary,
      accent: $web-frontend-accent,
      warn: $web-frontend-warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($web-frontend-theme);

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.

/* You can add global styles to this file, and also import other style files */
@import 'custom';
@import 'color';
@import 'card';
@import 'form';
@import 'input';
@import 'sizing';
@import 'spacing';
@import 'table';
@import 'text';
@import 'button';
@import 'svg_color';
@import 'font-family/font-family.scss';
@import '@danielmoncada/angular-datetime-picker/assets/style/picker.min.css';

// Tailwind
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: Inter, 'Helvetica Neue', sans-serif;
  background-color: var(--color-bg-primary);
}

:root {
  --color-primary: #2563eb; /* Màu mặc định */
  --color-active: #2563eb;
  --color-activeHV: #2563eb;
  --color-bg-primary: #f3f9ff;
  --color-setting: #1d1d1b;
  --color-table-header: #f3f4f6;
  --color-error: #dc2626;
  --color-exam-success: #00b342;
  --color-exam-error: #d2001a;
  --color-exam-bg-error: #fde8e8;
  --color-exam-bg-success: #def7ec;
}
