.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-justify {
  text-align: justify !important;
}

.text-danger {
  color: #eb0000 !important;
}

.text-success {
  color: #259323 !important;
}

.text-warning {
  color: #d78d1f !important;
}

// .text-primary {
//     color: #3b82f6 !important;
// }

.text-info {
  color: #20a8d8 !important;
}

.text-secondary {
  color: #949494 !important;
}
